import React from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
} from "reactstrap";
import { isMobile } from "react-device-detect";
import { AppStoreButton, GooglePlayButton } from "react-mobile-app-button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import messaging_app_img from "../images/messaging_app.svg";

export default function Landing3() {
  const mobileAppButtonWidth = 200;
  const googlePlayUrl = !isMobile
    ? "https://play.google.com/apps/testing/com.joinsupple.supple"
    : "https://play.google.com/store/apps/details?id=com.joinsupple.supple";
  const appStoreUrl = "https://testflight.apple.com/join/5CAdZmAX";

  return (
    <>
      <header>
        <Navbar>
          <NavbarBrand href="/">
            <h1 className="title font-weight-bold text-capitalize">Yapply</h1>
          </NavbarBrand>

          <Nav className="me-auto" navbar>
            <NavItem>
              <Button
                className="btn-icon"
                color="primary"
                href="mailto: fbeqiri@yapply.app"
                size="lg"
                type="button"
              >
                <span className="btn-inner--icon mr-1">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <span className="btn-inner--text">Get in touch</span>
              </Button>
            </NavItem>
          </Nav>
        </Navbar>
      </header>

      <main>
        <section className="section section-lg" id="landing">
          <Container
            className={`d-flex justify-content-center ${
              !isMobile ? "" : "fluid"
            }`}
          >
            <Row className={`${!isMobile ? "" : "text-center"}`}>
              <Col className="align-self-center order-1" xs="12" sm="12" md="7">
                <h1 className="font-weight-bold">
                  Your place where you and your fellow students can...
                </h1>
                <p className="lead mb-4">
                  ...make new friends, belong to any group, where you and other
                  students can talk every day and hang out more often. Talk
                  about your interests or uni life, everything is possible!
                </p>
                <p className="lead font-weight-bold">Download our app Beta</p>
                <Container className="mb-6">
                  <Row className={!isMobile ? "" : "justify-content-center"}>
                    <AppStoreButton
                      className="mr-3 mb-3"
                      width={mobileAppButtonWidth}
                      url={appStoreUrl}
                      theme={"dark"}
                    />
                    <GooglePlayButton
                      className="mr-3 mb-3"
                      width={mobileAppButtonWidth}
                      url={googlePlayUrl}
                      theme={"dark"}
                    />
                  </Row>
                </Container>
              </Col>
              {/* Show image only when not on mobile */}
              {!isMobile && (
                <Col className="align-self-center order-2" md="5">
                  <img alt="" className="img-fluid" src={messaging_app_img} />
                </Col>
              )}
            </Row>
          </Container>
        </section>
      </main>

      <footer></footer>
    </>
  );
}
